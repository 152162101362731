import { Icon, MultistepModal, WalCard, WalInput } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AzureIdentityFields from '@src/containers/Orgs/Accounts/components/AddAccountModal/components/AzureIdentityFields';
import GCPWorkloadIdentityFields from '@src/containers/Orgs/Accounts/components/AddAccountModal/components/GCPWorkloadIdentityFields';
import { useResourceAccountsCreateMutation } from '@src/hooks/react-query/resource-accounts/mutations/useResourceAccountsCreateMutation';
import { useResourceAccountsUpdateMutation } from '@src/hooks/react-query/resource-accounts/mutations/useResourceAccountsUpdateMutation';
import { useDecision } from '@src/hooks/useDecision';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import { units } from '@src/styles/variables';
import { getChangedFormFields, useWalhallForm } from '@src/utilities/form';
import { allLowerCaseExceptFirstWord } from '@src/utilities/string-utility';

import { AccountOption } from '../../Accounts';
import { TestAccountResponse } from '../TestAccountResponse';
import { AivenAndCloudflareFields } from './components/AivenAndClouflareFields';
import AWSFields from './components/AWSFields';
import AWSRoleFields from './components/AWSRoleFields';
import AzureFields from './components/AzureFields';
import GCPFields from './components/GCPFields';

const AccountCard = styled(WalCard)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${units.margin.sm};
`;

const getIsCurrentFormDirty = (obj: Record<string, any>, str: string) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key.startsWith(str) && obj[key] === true) {
      return true;
    }
  }
  return false;
};

interface AddAccountModalProps {
  /** The open state of the modal */
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  /** The ResourceAccount we are editing. If this is passed, the modal will perform an UPDATE, instead of a POST */
  editingAccountState?: [
    ResourceAccount | undefined,
    Dispatch<SetStateAction<ResourceAccount | undefined>>,
  ];
  accountType: ResourceAccountType | undefined;
  /** Optional className */
  className?: string;
  accountOptions?: AccountOption[];
  /** is component being rendered from Accounts Page? This component is also being used in ResourceDefinitionForm */
  isAccountPage?: boolean;
}

const parseCredential = (credential: any): Record<string, unknown> | null => {
  try {
    return JSON.parse(credential);
  } catch (_) {
    return null;
  }
};

/**
 * Build up the credentials. It will differ depending on what ResourceAccount.
 * - AWS is split into two fields and combined.
 * - GCP will just parse the provided JSON.
 *
 * @param formValue The value from the form.
 */
const buildCredential = (
  formValues: any,
  accountType?: ResourceAccountType
): Record<string, unknown> | null => {
  const credentials: {
    aws_access_key_id?: string;
    aws_secret_access_key?: string;
    external_id?: string;
    aws_role?: string;
  } = {};

  switch (accountType) {
    case 'gcp':
    case 'azure':
      return formValues[`${accountType}-token`]
        ? parseCredential(formValues[`${accountType}-token`])
        : null;
    case 'aiven':
    case 'cloudflare':
      return formValues[`${accountType}-token`]
        ? { token: formValues[`${accountType}-token`] }
        : null;
    case 'aws':
      if (formValues[`${accountType}-accessKeyId`]) {
        credentials.aws_access_key_id = formValues[`${accountType}-accessKeyId`];
      }
      if (formValues[`${accountType}-secretAccessKey`]) {
        credentials.aws_secret_access_key = formValues[`${accountType}-secretAccessKey`];
      }
      return Object.keys(credentials).length ? credentials : null;
    case 'aws-role':
      if (formValues[`${accountType}-externalId`]) {
        credentials.external_id = formValues[`${accountType}-externalId`];
      }
      if (formValues[`${accountType}-awsRole`]) {
        credentials.aws_role = formValues[`${accountType}-awsRole`];
      }
      return Object.keys(credentials).length ? credentials : null;
    case 'gcp-identity':
      const gcpIdentityFormValues = { ...formValues };
      delete gcpIdentityFormValues['gcp-identity-name'];
      return gcpIdentityFormValues;
    case 'azure-identity':
      const azureIdentityFormValues = { ...formValues };
      delete azureIdentityFormValues['azure-identity-name'];
      return azureIdentityFormValues;
    default:
      return null;
  }
};

const AddAccountModal = ({
  openState,
  editingAccountState,
  accountType,
  className,
  accountOptions,
  isAccountPage = false,
}: AddAccountModalProps) => {
  // Form
  const methods = useWalhallForm();
  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods;

  // Component state
  /** Whether the modal is adding or creating ResourceAccount */
  const [editingOrCreating, setEditingOrCreating] = useState<'editing' | 'creating'>('creating');
  const [editingAccount, setEditingAccount] = editingAccountState || [];
  const [open, setOpen] = openState;
  const [currentStep, setCurrentStep] = useState(0);

  // i18n
  const { t } = useTranslation();
  const accountsTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS;
  const formTranslations = accountsTranslations.ACCOUNT_MODAL;
  const uiTranslations = t('UI');

  // Optimizely
  const [testCloudAccountDecision] = useDecision('test-cloud-account');

  useEffect(() => {
    if ((editingOrCreating === 'creating' && currentStep) || editingOrCreating === 'editing') {
      // set default values to empty for fields of accountType so that isDirty and dirtyFields are accurate
      reset(getValues(), { keepDirtyValues: true });
    }
  }, [currentStep, editingOrCreating, getValues, reset]);

  useEffect(() => {
    return () => {
      setCurrentStep(0);
    };
  }, []);

  // React Query
  const {
    mutate: createResourceAccount,
    error: createResourceAccountError,
    isSuccess: isCreated,
    isPending: isCreating,
    reset: resetCreateResourceAccount,
  } = useResourceAccountsCreateMutation();
  const {
    mutate: createResourceAccountDryRun,
    error: dryRunError,
    status: dryRunStatus,
    reset: resetResourceAccountDryRun,
    isPending: isTesting,
  } = useResourceAccountsCreateMutation(true);
  const {
    mutate: updateResourceAccount,
    isSuccess: isUpdated,
    error: updateResourceAccountError,
    reset: resetUpdateResourceAccount,
  } = useResourceAccountsUpdateMutation();

  useEffect(() => {
    if (createResourceAccountError?.response?.status === 409) {
      setError(`${accountType}-name`, { type: 'manual', message: formTranslations.NAME_EXISTS });
    }
  }, [createResourceAccountError, setError, formTranslations, accountType]);

  useEffect(() => {
    if (isCreated || isUpdated) {
      setOpen(false);
    }
  }, [isCreated, setOpen, isUpdated]);

  useEffect(() => {
    resetResourceAccountDryRun();
  }, [open, resetResourceAccountDryRun, currentStep]);

  /**
   * Clear form on unmount.
   */
  useEffect(() => {
    return () => {
      reset();
      setEditingAccount?.(undefined);
      clearErrors();
      setCurrentStep(0);
    };
  }, [open, setEditingAccount, clearErrors, reset]);

  useEffect(() => {
    setEditingOrCreating(editingAccount ? 'editing' : 'creating');
  }, [editingAccount]);

  /**
   * If in editing mode, set the name to the ResourceAccount
   */
  useEffect(() => {
    if (editingAccount && !getValues().name) {
      setValue(`${editingAccount.type}-name`, editingAccount.name);
    }
  }, [editingAccount, getValues, setValue]);

  /**
   * Dispatch actions for Create or Update
   *
   * @param name The name from the form.
   * @param credential The token from the form.
   */
  const handleResourceAccountCreateOrUpdate = (
    name: string,
    credential: Record<string, unknown> | null,
    dryRun?: boolean
  ) => {
    if (dryRun && accountType) {
      createResourceAccountDryRun({ credential, name, resourceAccountType: accountType });
    } else if (editingAccount && editingOrCreating === 'editing') {
      updateResourceAccount({ resourceAccountId: editingAccount.id, name, credential });
    } else if (editingOrCreating === 'creating' && accountType) {
      createResourceAccount({ credential, name, resourceAccountType: accountType });
    }
  };

  /**
   * On Authorize button click.
   *
   * @param formValue The submitted form.
   */
  const handleAuthorizeClick = async (
    formValues: any,
    type: ResourceAccountType,
    dryRun?: boolean
  ) => {
    resetCreateResourceAccount();
    resetResourceAccountDryRun();
    resetUpdateResourceAccount();
    handleResourceAccountCreateOrUpdate(
      formValues[`${type}-name`],
      buildCredential(getChangedFormFields(formValues, dirtyFields), accountType),
      dryRun
    );
  };

  const resourceAccountFields = () => {
    switch (accountType) {
      case 'gcp':
        return <GCPFields namePrefix={accountType} isNew={!editingAccount} />;
      case 'gcp-identity':
        return <GCPWorkloadIdentityFields isNew={!editingAccount} />;
      case 'aws':
        return <AWSFields namePrefix={accountType} />;
      case 'aws-role':
        return <AWSRoleFields namePrefix={accountType} />;
      case 'aiven':
      case 'cloudflare':
        return <AivenAndCloudflareFields namePrefix={accountType} />;
      case 'azure':
        return <AzureFields namePrefix={accountType} isNew={!editingAccount} />;
      case 'azure-identity':
        return <AzureIdentityFields isNew={!editingAccount} />;
      default:
        return <></>;
    }
  };

  const getTitle = () => {
    if (accountType === 'gcp' || editingAccount?.type === 'gcp') {
      return formTranslations.GCP.TITLE;
    } else if (accountType === 'aws' || editingAccount?.type === 'aws') {
      return formTranslations.AWS.TITLE;
    } else if (accountType === 'gcp-identity' || editingAccount?.type === 'gcp-identity') {
      return formTranslations.GCP_IDENTITY.TITLE;
    } else if (accountType === 'aws-role' || editingAccount?.type === 'aws-role') {
      return formTranslations.AWS_ROLE.TITLE;
    } else if (accountType === 'aiven' || editingAccount?.type === 'aiven') {
      return formTranslations.AIVEN.TITLE;
    } else if (accountType === 'azure' || editingAccount?.type === 'azure') {
      return formTranslations.AZURE.TITLE;
    } else if (accountType === 'azure-identity' || editingAccount?.type === 'azure-identity') {
      return formTranslations.AZURE_IDENTITY.TITLE;
    } else if (accountType === 'cloudflare' || editingAccount?.type === 'cloudflare') {
      return formTranslations.CLOUDFLARE.TITLE;
    }
  };

  const disableSaveButton = accountType && !getIsCurrentFormDirty(dirtyFields, accountType);

  return (
    <MultistepModal
      currentStepState={[currentStep, setCurrentStep]}
      openState={openState}
      size={'large'}
      className={className}
      disableClickOutside={isDirty}
      steps={[
        ...((accountType && !isAccountPage) || editingAccount
          ? []
          : [
              {
                title: accountsTranslations.ADD_ACCOUNT,
                actions: [
                  {
                    callback: () => {
                      setCurrentStep(0);
                    },
                    label: uiTranslations.CLOSE,
                  },
                ],
                content: accountOptions?.map((option) => (
                  <AccountCard
                    cardStyle={'base'}
                    highlightOnHover
                    onClick={() => {
                      option.onClick();
                      setCurrentStep(1);
                    }}
                    showRightArrow
                    key={option.name}
                    dataTestId={option.testingId}>
                    <div className={'flex-centered'}>
                      <Icon marginRight={'md'} name={option.icon} />
                      {allLowerCaseExceptFirstWord(option.name)}
                    </div>
                  </AccountCard>
                )),
              },
            ]),
        {
          title: getTitle(),
          content: (
            <FormProvider {...methods}>
              <WalInput
                name={`${accountType}-name`}
                className={'mb-md'}
                label={formTranslations.NAME_LABEL}
                labelExtensionText={formTranslations.NAME_PLACEHOLDER}
                required
                maxLength={63}
                dataTestId={'account'}
                standardValidation={[{ type: 'name' }]}
              />
              {resourceAccountFields()}
              {testCloudAccountDecision.enabled && (
                <TestAccountResponse
                  testError={
                    updateResourceAccountError?.response?.data ||
                    createResourceAccountError?.response?.data ||
                    dryRunError?.response?.data
                  }
                  testSuccess={{ success: Boolean(dryRunStatus === 'success') }}
                />
              )}
            </FormProvider>
          ),
          actions: [
            { label: editingAccount ? uiTranslations.CLOSE : uiTranslations.BACK, group: 'left' },
            {
              label: uiTranslations.TEST,
              group: 'right',
              disableDefaultAction: true,
              loading: isTesting,
              disabled: isTesting || disableSaveButton,
              hide: !testCloudAccountDecision.enabled,
              callback:
                accountType &&
                handleSubmit((args) => handleAuthorizeClick(args, accountType, true)),
            },
            {
              className: 'qa-gcp-authorize',
              loading: isCreating,
              disabled: isCreating || disableSaveButton,
              callback:
                accountType && handleSubmit((args) => handleAuthorizeClick(args, accountType)),
              label: testCloudAccountDecision.enabled
                ? uiTranslations.SAVE
                : uiTranslations.AUTHORIZE,
              disableDefaultAction: true,
              group: 'right',
              variant: testCloudAccountDecision.enabled ? 'primary' : undefined,
            },
          ],
        },
      ]}
    />
  );
};

export default AddAccountModal;
