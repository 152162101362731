import { Button, WalInput } from '@humanitec/ui-components';
import { isEmpty } from 'lodash';
import { rem } from 'polished';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import useReplicaUpdateMutation from '@src/hooks/react-query/workload-profiles/mutation/useReplicaUpdateMutation';
import { useDebouncedValue } from '@src/hooks/useDebouncedValue';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

import ReplicaStatuses from './ReplicaStatuses';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomWalInput = styled(WalInput)`
  width: ${rem(190)};
`;

const ReplicasWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: ${rem(10)};
  right: ${rem(10)};
  font-size: ${units.fontSize.sm};
`;

const ReplicaAmount = styled.span`
  margin-right: ${units.margin.sm};
  display: flex;
  align-items: center;
`;

const EditReplicasWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Replicas = () => {
  // i18n
  const { t } = useTranslation();
  const replicasTranslations = t('VIEW_MODULE').SECTIONS;
  const translations = t('UI');

  // Component state
  const [showForm, setShowForm] = useState(false);

  const { orgRole, appRole, envTypeRole } = useGetUserRoles();
  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery();
  const { workloadController, environmentRuntime } = environmentRuntimeData || {};

  // Form
  const methods = useWalhallForm({
    values: {
      replicas: workloadController?.replicas.toString() || '0',
    },
  });

  // Router hooks
  const { orgId, appId, envId, moduleId } = useParams<keyof MatchParams>() as MatchParams;

  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery({ appId, orgId });

  const envPauseStatus = envPauseStatusMap?.[envId];

  const controllerCount = Object.keys(
    environmentRuntime?.modules[moduleId].controllers || {}
  ).length;

  // React Query
  const {
    mutate: updateReplicas,
    error: updateReplicaError,
    isPending: isUpdatingReplicas,
    isSuccess: areReplicasUpdated,
    reset: resetReplicaQuery,
  } = useReplicaUpdateMutation({
    onSuccess: () => {
      reset();
      setShowForm(false);
    },
  });

  const handleSubmitReplicas = (form: any) => {
    const payload = {
      [moduleId]: Number(form.replicas),
    };

    updateReplicas(payload);
  };

  const {
    formState: { errors, isDirty },
    setError,
    clearErrors,
    handleSubmit,
    reset,
    watch,
  } = methods;

  const currentReplicaValue: string | undefined = watch('replicas');
  const currentReplicaValueNumber = Number(currentReplicaValue);
  const debouncedPodsLength = useDebouncedValue(workloadController?.pods.length || 0, 300);
  const debouncedReplicas = useDebouncedValue(workloadController?.replicas, 300);

  const hasOneReplica = currentReplicaValueNumber === 1;

  const errorMessage = updateReplicaError?.response?.data.message;

  const isChangingTarget = areReplicasUpdated && debouncedPodsLength !== debouncedReplicas;

  const hasErrors = !isEmpty(errors);

  useEffect(() => {
    reset({ replicas: debouncedReplicas?.toString() });
  }, [debouncedReplicas, reset]);

  useEffect(() => {
    if (currentReplicaValue?.trim() === '') {
      setError('replicas', { message: translations.INPUT_ERROR.REQUIRED, type: 'required' });
    } else if (currentReplicaValueNumber < 0) {
      setError('replicas', { message: `${translations.INPUT_ERROR.MIN_IS} 0`, type: 'min' });
    } else if (errorMessage) {
      setError('replicas', { message: errorMessage });
    } else {
      if (currentReplicaValue && hasErrors) {
        clearErrors();
      }
    }
  }, [
    clearErrors,
    currentReplicaValue,
    currentReplicaValueNumber,
    errorMessage,
    hasErrors,
    setError,
    translations.INPUT_ERROR.MIN_IS,
    translations.INPUT_ERROR.REQUIRED,
  ]);

  if (controllerCount > 1) {
    return (
      <div className={'txt-md'}>
        {replicasTranslations.REPLICAS_MULTIPLE_CONTROLLERS_1}
        <Link
          className={'txt-md'}
          to={'https://api-docs.humanitec.com/#tag/RuntimeInfo/operation/getRuntime'}>
          {replicasTranslations.REPLICAS_API_DOCUMENTATION}
        </Link>{' '}
        {replicasTranslations.REPLICAS_MULTIPLE_CONTROLLERS_2}
      </div>
    );
  }

  return (
    <Container>
      <ReplicasWrapper>
        {((appRole && ['owner', 'developer'].includes(appRole) && envTypeRole === 'deployer') ||
          orgRole === 'administrator') && (
          <EditReplicasWrapper>
            {!showForm ? (
              <ReplicaAmount>
                <Trans
                  values={{ targeted: currentReplicaValue || 0 }}
                  defaults={
                    !hasOneReplica
                      ? replicasTranslations.REPLICAS_TARGETS_PLURAL
                      : replicasTranslations.REPLICAS_TARGETS
                  }
                />

                {Boolean(debouncedPodsLength) && (
                  <>
                    {', '}
                    <Trans
                      values={{ exists: debouncedPodsLength }}
                      defaults={
                        debouncedPodsLength > 1
                          ? replicasTranslations.REPLICAS_EXISTS_PLURAL
                          : replicasTranslations.REPLICAS_EXISTS
                      }
                    />
                  </>
                )}
                {!envPauseStatus &&
                  workloadController &&
                  ((appRole &&
                    ['owner', 'developer'].includes(appRole) &&
                    envTypeRole === 'deployer') ||
                    orgRole === 'administrator') && (
                    <Button
                      loading={isChangingTarget}
                      disabled={isChangingTarget}
                      onClick={() => setShowForm(true)}
                      className={'ml-md'}
                      iconLeft={'edit'}
                      size={'small'}
                      variant={'secondary'}>
                      {isChangingTarget
                        ? replicasTranslations.CHANGING_TARGET
                        : replicasTranslations.CHANGE_TARGET}
                    </Button>
                  )}
              </ReplicaAmount>
            ) : (
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSubmitReplicas)}>
                  <CustomWalInput
                    noMargin
                    type={'number'}
                    name={'replicas'}
                    min={0}
                    required
                    className={'mb-md'}
                  />
                  <Button
                    loading={isUpdatingReplicas}
                    disabled={!isDirty || hasErrors}
                    type={'submit'}
                    className={'mr-md'}
                    size={'small'}>
                    {translations.SAVE}
                  </Button>
                  <Button
                    variant={'secondary'}
                    disabled={isUpdatingReplicas}
                    size={'small'}
                    onClick={() => {
                      if (isDirty) {
                        reset();
                      }
                      resetReplicaQuery();
                      setShowForm(false);
                    }}>
                    {translations.CANCEL}
                  </Button>
                </form>
              </FormProvider>
            )}
          </EditReplicasWrapper>
        )}
      </ReplicasWrapper>
      {<ReplicaStatuses />}
    </Container>
  );
};
export default Replicas;
