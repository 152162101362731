import { Toggle, WalCard, WarningSection } from '@humanitec/ui-components';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import useMatchingCriteriaCreateMutation from '@src/hooks/react-query/resources/mutations/useMatchingCriteriaCreateMutation';
import useMatchingCriteriaDeleteMutation from '@src/hooks/react-query/resources/mutations/useMatchingCriteriaDeleteMutation';
import useActiveResourcesQuery from '@src/hooks/react-query/resources/queries/useActiveResourcesQuery';
import { useDecision } from '@src/hooks/useDecision';
import { ResourceDefinition } from '@src/models/resources';
import { useWalhallForm } from '@src/utilities/form';
import { generateResourceDefinitionUrl } from '@src/utilities/navigation';

const Wrapper = styled.div`
  flex-direction: row;
  align-items: start;
  flex-wrap: nowrap;
`;
interface ToggleDefaultResourceDefinitionProps {
  resourceDefinition: ResourceDefinition;
}
const ToggleDefaultResourceDefinition = ({
  resourceDefinition,
}: ToggleDefaultResourceDefinitionProps) => {
  // Optimizely
  const [resourceClassCatalogDecision] = useDecision('resource-class-catalog');
  // i18n
  const { t } = useTranslation();
  const translations = t('MATCHING_CRITERIA');

  // react query
  const { mutate: addMatchingCriteria } = useMatchingCriteriaCreateMutation();
  const { mutate: deleteMatchingCriteria } = useMatchingCriteriaDeleteMutation();
  const { data: activeResources, isFetched: activeResourcesLoaded } = useActiveResourcesQuery();

  const defaultResourceDefinitionIsActive =
    resourceDefinition.criteria && resourceDefinition.criteria?.length > 0;

  // form
  const methods = useWalhallForm({
    defaultValues: {
      'toggle-default-resource-def': defaultResourceDefinitionIsActive,
    },
  });

  const handleToggleResourceDefinition = (value?: boolean) => {
    if (value) {
      addMatchingCriteria({
        defId: resourceDefinition.id,
        criteria: {
          class: 'default',
        },
      });
    } else if (resourceDefinition.criteria) {
      deleteMatchingCriteria({
        defId: resourceDefinition?.id,
        criteria: resourceDefinition.criteria?.[0],
        force: true,
      });
    }
  };

  return (
    <>
      <span className={'txt-base mb-lg'}>{translations.DEFAULT_RESOURCE_DEFINITION_TEXT}</span>
      {activeResourcesLoaded && activeResources && activeResources?.length > 0 && (
        <WarningSection>
          <p className={'txt-base'}>
            <Trans defaults={translations.DEFAULT_RESOURCE_DEFINITION_IN_USE}>
              <Link
                className={'txt-base'}
                to={generateResourceDefinitionUrl(
                  resourceDefinition.org_id,
                  resourceDefinition?.id,
                  resourceClassCatalogDecision.enabled
                )}
                target={'_blank'}
              />
            </Trans>
          </p>
        </WarningSection>
      )}
      <WalCard>
        <FormProvider {...methods}>
          <Wrapper className={'flex-row flex-centered'}>
            <Toggle
              name={'toggle-default-resource-def'}
              onChange={handleToggleResourceDefinition}
            />
            {defaultResourceDefinitionIsActive ? (
              <div className={'flex-column'}>
                <span className={'mb-md'}>{translations.ACTIVE}</span>
                <span className={'txt-translucent'}>
                  {translations.DEFAULT_RESOURCE_DEFINITION_ACTIVE_SUBTEXT}
                </span>
              </div>
            ) : (
              <div className={'flex-column'}>
                <span className={'mb-md'}>{translations.INACTIVE}</span>
                <span className={'txt-translucent'}>
                  {translations.DEFAULT_RESOURCE_DEFINITION_INACTIVE_SUBTEXT}
                </span>
              </div>
            )}
          </Wrapper>
        </FormProvider>
      </WalCard>
    </>
  );
};

export default ToggleDefaultResourceDefinition;
