import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';

import { Button } from '../../base/Button/Button';
import { WalPanel } from '../../base/Panel/Panel';
import MultiFilterConditions, {
  FilterByOption,
  FilterCondition,
} from '../MultiFilter/MultiFilterConditions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${units.padding.xxl};
`;

export interface FilterConditions {
  [sectionId: string]: FilterCondition[];
}

export interface FilterByOptions {
  [sectionId: string]: {
    title?: string;
    options: FilterByOption[];
  };
}

interface MultiFilterPanelProps {
  filterByOptions: FilterByOptions;
  onFiltersChange: (conditions: FilterConditions) => void;
  defaultConditions?: FilterConditions;
  disabled?: boolean;
}

/**
 * @description The MultiFilter component is responsible for rendering a panel with multiple filter sections.
 * It uses the MultiFilterConditions component to render the filter conditions for each section.
 * The filter conditions and options are provided through the props.
 * When the filter conditions are changed, the onFiltersChange callback is called with the updated conditions.
 * The defaultConditions prop can be used to set the initial filter conditions.
 * The MultiFilter component also handles applying and canceling the filters.
 */

export const MultiFilter = ({
  onFiltersChange,
  defaultConditions,
  filterByOptions,
  disabled = false,
}: MultiFilterPanelProps) => {
  // state
  const [open, setOpen] = useState<boolean>(false);
  const [filterConditions, setFilterConditions] = useState<FilterConditions>(
    defaultConditions || {}
  );
  const [filterSetFromUrl, setFilterSetFromUrl] = useState<boolean>(false);

  // router
  const [searchParams, setSearchParams] = useSearchParams();
  const conditionsFromURL = searchParams.get('filters');

  useEffect(() => {
    if (!filterSetFromUrl) {
      try {
        if (conditionsFromURL) {
          setFilterConditions(JSON.parse(conditionsFromURL));
          onFiltersChange(JSON.parse(conditionsFromURL));
          setFilterSetFromUrl(true);
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    }
  }, [onFiltersChange, conditionsFromURL, filterSetFromUrl]);

  useEffect(() => {
    if (defaultConditions) {
      setFilterConditions(defaultConditions);
    }
  }, [defaultConditions]);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const handleFilterChange = (filterSection: string, conditions: FilterCondition[]) => {
    setFilterConditions((prevState) => {
      const newFilterConditions = { ...prevState };
      newFilterConditions[filterSection] = conditions;
      return newFilterConditions;
    });
  };

  const applyFilters = () => {
    const newFilterConditions = { ...filterConditions };
    // remove conditions that have no values
    Object.keys(filterConditions).forEach((section) => {
      newFilterConditions[section] = filterConditions[section].filter((condition) =>
        condition.values
          ? condition.values.length > 0
          : condition.dateRange?.from || condition.dateRange?.to
      );
    });
    setSearchParams(`filters=${JSON.stringify(newFilterConditions)}`);
    onFiltersChange(newFilterConditions);
    setOpen(false);
  };

  const cancel = () => {
    setSearchParams(`filters=${JSON.stringify(defaultConditions)}`);
    onFiltersChange(defaultConditions || {});
    setOpen(false);
  };

  const appliedFiltersCount = Object.values(defaultConditions || {}).reduce((acc, conditions) => {
    return acc + conditions.length;
  }, 0);

  return (
    <>
      <WalPanel
        disabled={disabled}
        openState={[open, setOpen]}
        toggle={
          <Button className={'mr-md'} variant={'secondary'} iconLeft={'filter'} disabled={disabled}>
            {/* shows the total number of applied filter conditions on  the button*/}
            {appliedFiltersCount > 0
              ? appliedFiltersCount === 1
                ? `1 ${uiTranslations.FILTER_APPLIED}`
                : `${appliedFiltersCount} ${uiTranslations.FILTERS_APPLIED}`
              : uiTranslations.FILTERS}
          </Button>
        }
        borderRadius
        panelSize={'large'}
        customWidth={800}
        disableFloatingUiResize>
        <Wrapper>
          {Object.keys(filterByOptions).map((section) => (
            <MultiFilterConditions
              key={section}
              sectionId={section}
              title={filterByOptions[section].title}
              filterByOptions={filterByOptions[section].options}
              defaultConditions={filterConditions?.[section]}
              onFilterChange={(newConditions) => handleFilterChange(section, newConditions)}
            />
          ))}
          <div className={'flex-centered'}>
            <Button className={'mr-md'} onClick={applyFilters}>
              {uiTranslations.APPLY}
            </Button>
            <Button variant={'secondary'} onClick={cancel}>
              {uiTranslations.CANCEL}
            </Button>
          </div>
        </Wrapper>
      </WalPanel>
    </>
  );
};

export default MultiFilter;
